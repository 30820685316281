<template>
  <div class="system-wrapper" v-if="init">
    <div class="sync product-sync">
      <div class="sync-info">
        <h2>Product sync</h2>
        <p class="desc">Runs every minute</p>
        <button
          v-if="userRole.slug === 'superAdmin'"
          class="run-button sync-products"
          :class="{ loading: productSyncLoader }"
          @click="syncProducts"
        >
          <span v-if="!productSyncLoader">Run manually</span>
          <span v-else>
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="381.496px"
              height="381.496px"
              viewBox="0 0 381.496 381.496"
              style="enable-background: new 0 0 381.496 381.496"
              xml:space="preserve"
            >
              <g>
                <g>
                  <g id="Layer_5_13_">
                    <g>
                      <path
                        d="M353.165,182.381c1.217-2.613,9.533-13.636,17.062-25.062c0.007-0.008,0.013-0.016,0.017-0.023
                        c1.699-2.578,3.355-5.175,4.885-7.702c0.043-0.071,0.086-0.143,0.129-0.214c0.248-0.412,17.859-28.646-7.225-17.212
                        c0,0.002-0.002,0.005-0.004,0.007c-4.713,2.417-10.707,6.021-18.244,11.072c-16.441,11.021-49.885,27.154-49.885,27.154
                        s-5.82,3.144-9.658,0.749c-19.396-12.1-47.656-33.594-84.912-45.562c-0.621-0.2-1.408-0.673-0.338-1.664l15.955-11.158
                        c0,0,1.25-1.08-0.355-1.602c-7.896-2.573-40.783-13.601-69.24-3.462c-5.797,2.065-10.555,3.761-14.467,5.155
                        c-1.682,0.6-3.391,1.305-6.799,1.726C52.482,117.237,0,174.203,0,196.737c0,15.123,8.154,25.271,37.947,39.378
                        c0.598-0.095,5.146,3.17,15.137,0.168c2.678-0.805,21.697-7.968,22.453-8.291c0.758-0.346,1.25-0.517,1.564-0.466
                        c0.404,0.064,0.701,0.962,0.699,1.144c-0.063,5.387-10.16,9.75-15.893,14.537c-0.984,0.459-1.248,2.744,0.475,3.484
                        c0.002,0,20.246,10.854,52.307,14.229c2.592,0.273,36.34,21.897,70.371,16.096c17.999-3.069,26.564-4.119,30.473-5.197
                        c3.412-0.94,1.783-2.022,1.783-2.022l-17.059-13.592c-1.155-1.281-0.221-2.265,0.746-2.539
                        c37.882-10.779,67.834-27.771,85.672-42.328c2.402-1.961,8.645,2.701,13.102,4.953c14.801,7.477,76.238,32.803,81.301,27.442
                        c0.436-0.452,0.467-1.125,0.023-2.05C372.456,223.524,341.21,208.035,353.165,182.381z M62.835,180.632
                        c-5.465,0-9.895-4.512-9.895-10.077s4.43-10.076,9.895-10.076s9.896,4.511,9.896,10.076S68.3,180.632,62.835,180.632z
                        M107.118,237.965c-0.609,0.547-1.164,1.373-0.842,0.185c0,0,15.426-23.21,17.426-53.211
                        c1.498-22.484-13.482-50.02-13.482-50.02s0.029-0.804,0.555-0.169C116.108,141.2,168.618,182.688,107.118,237.965z"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </span>
        </button>
      </div>

      <div class="system-log product-sync-log">
        <div
          v-for="entry in productSyncLog"
          :key="entry.id"
          class="single-log-entry"
        >
          <div class="log-entry-top">
            <span class="completed">{{
              entry.completedAt.toDate() | moment("from", "now")
            }}</span>
            <span class="ran-by">{{ entry.ranBy }}</span>
          </div>

          <div class="log-entry-data">
            <h6 class="added">
              Added products: <span>{{ entry.added.length }}</span>
            </h6>
            <p
              v-for="(info, index) in entry.added"
              :key="entry.id + '-added-' + index"
              class="log-entry-line"
            >
              {{ info }}
            </p>

            <h6 class="updated">
              Updated products: <span>{{ entry.updated.length }}</span>
            </h6>
            <p
              v-for="(info, index) in entry.updated"
              :key="entry.id + '-updated-' + index"
              class="log-entry-line"
            >
              {{ info }}
            </p>
            <h6 class="removed">
              Removed products: <span>{{ entry.removed.length }}</span>
            </h6>
            <p
              v-for="(info, index) in entry.removed"
              :key="entry.id + '-deleted-' + index"
              class="log-entry-line"
            >
              {{ info }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="sync company-sync">
      <div class="sync-info">
        <h2>Company sync</h2>
        <p class="desc">Runs every 10 minutes</p>
        <button
          v-if="userRole.slug === 'superAdmin'"
          class="run-button sync-products"
          :class="{ loading: companySyncLoader }"
          @click="syncCompanies"
        >
          <span v-if="!companySyncLoader">Run manually</span>
          <span v-else>
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="381.496px"
              height="381.496px"
              viewBox="0 0 381.496 381.496"
              style="enable-background: new 0 0 381.496 381.496"
              xml:space="preserve"
            >
              <g>
                <g>
                  <g id="Layer_5_13_">
                    <g>
                      <path
                        d="M353.165,182.381c1.217-2.613,9.533-13.636,17.062-25.062c0.007-0.008,0.013-0.016,0.017-0.023
                        c1.699-2.578,3.355-5.175,4.885-7.702c0.043-0.071,0.086-0.143,0.129-0.214c0.248-0.412,17.859-28.646-7.225-17.212
                        c0,0.002-0.002,0.005-0.004,0.007c-4.713,2.417-10.707,6.021-18.244,11.072c-16.441,11.021-49.885,27.154-49.885,27.154
                        s-5.82,3.144-9.658,0.749c-19.396-12.1-47.656-33.594-84.912-45.562c-0.621-0.2-1.408-0.673-0.338-1.664l15.955-11.158
                        c0,0,1.25-1.08-0.355-1.602c-7.896-2.573-40.783-13.601-69.24-3.462c-5.797,2.065-10.555,3.761-14.467,5.155
                        c-1.682,0.6-3.391,1.305-6.799,1.726C52.482,117.237,0,174.203,0,196.737c0,15.123,8.154,25.271,37.947,39.378
                        c0.598-0.095,5.146,3.17,15.137,0.168c2.678-0.805,21.697-7.968,22.453-8.291c0.758-0.346,1.25-0.517,1.564-0.466
                        c0.404,0.064,0.701,0.962,0.699,1.144c-0.063,5.387-10.16,9.75-15.893,14.537c-0.984,0.459-1.248,2.744,0.475,3.484
                        c0.002,0,20.246,10.854,52.307,14.229c2.592,0.273,36.34,21.897,70.371,16.096c17.999-3.069,26.564-4.119,30.473-5.197
                        c3.412-0.94,1.783-2.022,1.783-2.022l-17.059-13.592c-1.155-1.281-0.221-2.265,0.746-2.539
                        c37.882-10.779,67.834-27.771,85.672-42.328c2.402-1.961,8.645,2.701,13.102,4.953c14.801,7.477,76.238,32.803,81.301,27.442
                        c0.436-0.452,0.467-1.125,0.023-2.05C372.456,223.524,341.21,208.035,353.165,182.381z M62.835,180.632
                        c-5.465,0-9.895-4.512-9.895-10.077s4.43-10.076,9.895-10.076s9.896,4.511,9.896,10.076S68.3,180.632,62.835,180.632z
                        M107.118,237.965c-0.609,0.547-1.164,1.373-0.842,0.185c0,0,15.426-23.21,17.426-53.211
                        c1.498-22.484-13.482-50.02-13.482-50.02s0.029-0.804,0.555-0.169C116.108,141.2,168.618,182.688,107.118,237.965z"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </span>
        </button>
      </div>

      <div class="system-log product-sync-log">
        <div
          v-for="entry in companySyncLog"
          :key="entry.id"
          class="single-log-entry"
        >
          <div class="log-entry-top">
            <span class="completed">{{
              entry.completedAt.toDate() | moment("from", "now")
            }}</span>
            <span class="ran-by">{{ entry.ranBy }}</span>
          </div>

          <div class="log-entry-data">
            <h6 class="added">
              Added companies: <span>{{ entry.added.length }}</span>
            </h6>
            <p
              v-for="(info, index) in entry.added"
              :key="entry.id + '-added-' + index"
              class="log-entry-line"
            >
              {{ info }}
            </p>

            <h6 class="updated">
              Updated companies: <span>{{ entry.updated.length }}</span>
            </h6>
            <p
              v-for="(info, index) in entry.updated"
              :key="entry.id + '-updated-' + index"
              class="log-entry-line"
            >
              {{ info }}
            </p>
            <h6 class="removed">
              Removed companies: <span>{{ entry.removed.length }}</span>
            </h6>
            <p
              v-for="(info, index) in entry.removed"
              :key="entry.id + '-deleted-' + index"
              class="log-entry-line"
            >
              {{ info }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="sync user-sync">
      <div class="sync-info">
        <h2>User sync</h2>
        <p class="desc">Runs every 10 minutes</p>
        <button
          v-if="userRole.slug === 'superAdmin'"
          class="run-button sync-products"
          :class="{ loading: userSyncLoader }"
          @click="syncUsers"
        >
          <span v-if="!userSyncLoader">Run manually</span>
          <span v-else>
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="381.496px"
              height="381.496px"
              viewBox="0 0 381.496 381.496"
              style="enable-background: new 0 0 381.496 381.496"
              xml:space="preserve"
            >
              <g>
                <g>
                  <g id="Layer_5_13_">
                    <g>
                      <path
                        d="M353.165,182.381c1.217-2.613,9.533-13.636,17.062-25.062c0.007-0.008,0.013-0.016,0.017-0.023
                        c1.699-2.578,3.355-5.175,4.885-7.702c0.043-0.071,0.086-0.143,0.129-0.214c0.248-0.412,17.859-28.646-7.225-17.212
                        c0,0.002-0.002,0.005-0.004,0.007c-4.713,2.417-10.707,6.021-18.244,11.072c-16.441,11.021-49.885,27.154-49.885,27.154
                        s-5.82,3.144-9.658,0.749c-19.396-12.1-47.656-33.594-84.912-45.562c-0.621-0.2-1.408-0.673-0.338-1.664l15.955-11.158
                        c0,0,1.25-1.08-0.355-1.602c-7.896-2.573-40.783-13.601-69.24-3.462c-5.797,2.065-10.555,3.761-14.467,5.155
                        c-1.682,0.6-3.391,1.305-6.799,1.726C52.482,117.237,0,174.203,0,196.737c0,15.123,8.154,25.271,37.947,39.378
                        c0.598-0.095,5.146,3.17,15.137,0.168c2.678-0.805,21.697-7.968,22.453-8.291c0.758-0.346,1.25-0.517,1.564-0.466
                        c0.404,0.064,0.701,0.962,0.699,1.144c-0.063,5.387-10.16,9.75-15.893,14.537c-0.984,0.459-1.248,2.744,0.475,3.484
                        c0.002,0,20.246,10.854,52.307,14.229c2.592,0.273,36.34,21.897,70.371,16.096c17.999-3.069,26.564-4.119,30.473-5.197
                        c3.412-0.94,1.783-2.022,1.783-2.022l-17.059-13.592c-1.155-1.281-0.221-2.265,0.746-2.539
                        c37.882-10.779,67.834-27.771,85.672-42.328c2.402-1.961,8.645,2.701,13.102,4.953c14.801,7.477,76.238,32.803,81.301,27.442
                        c0.436-0.452,0.467-1.125,0.023-2.05C372.456,223.524,341.21,208.035,353.165,182.381z M62.835,180.632
                        c-5.465,0-9.895-4.512-9.895-10.077s4.43-10.076,9.895-10.076s9.896,4.511,9.896,10.076S68.3,180.632,62.835,180.632z
                        M107.118,237.965c-0.609,0.547-1.164,1.373-0.842,0.185c0,0,15.426-23.21,17.426-53.211
                        c1.498-22.484-13.482-50.02-13.482-50.02s0.029-0.804,0.555-0.169C116.108,141.2,168.618,182.688,107.118,237.965z"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </span>
        </button>
      </div>

      <div class="system-log product-sync-log">
        <div
          v-for="entry in userSyncLog"
          :key="entry.id"
          class="single-log-entry"
        >
          <div class="log-entry-top">
            <span class="completed">{{
              entry.completedAt.toDate() | moment("from", "now")
            }}</span>
            <span class="ran-by">{{ entry.ranBy }}</span>
          </div>

          <div class="log-entry-data">
            <h6 class="added">
              Added users: <span>{{ entry.added.length }}</span>
            </h6>
            <p
              v-for="(info, index) in entry.added"
              :key="entry.id + '-added-' + index"
              class="log-entry-line"
            >
              {{ info }}
            </p>

            <h6 class="updated">
              Updated users: <span>{{ entry.updated.length }}</span>
            </h6>
            <p
              v-for="(info, index) in entry.updated"
              :key="entry.id + '-updated-' + index"
              class="log-entry-line"
            >
              {{ info }}
            </p>
            <h6 class="removed">
              Removed users: <span>{{ entry.removed.length }}</span>
            </h6>
            <p
              v-for="(info, index) in entry.removed"
              :key="entry.id + '-deleted-' + index"
              class="log-entry-line"
            >
              {{ info }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="sync admin-user-sync">
      <div class="sync-info">
        <h2>Admin user sync</h2>
        <p class="desc">Runs every 10 minutes</p>
        <button
          v-if="userRole.slug === 'superAdmin'"
          class="run-button sync-products"
          :class="{ loading: adminUserSyncLoader }"
          @click="syncAdminUsers"
        >
          <span v-if="!adminUserSyncLoader">Run manually</span>
          <span v-else>
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="381.496px"
              height="381.496px"
              viewBox="0 0 381.496 381.496"
              style="enable-background: new 0 0 381.496 381.496"
              xml:space="preserve"
            >
              <g>
                <g>
                  <g id="Layer_5_13_">
                    <g>
                      <path
                        d="M353.165,182.381c1.217-2.613,9.533-13.636,17.062-25.062c0.007-0.008,0.013-0.016,0.017-0.023
                        c1.699-2.578,3.355-5.175,4.885-7.702c0.043-0.071,0.086-0.143,0.129-0.214c0.248-0.412,17.859-28.646-7.225-17.212
                        c0,0.002-0.002,0.005-0.004,0.007c-4.713,2.417-10.707,6.021-18.244,11.072c-16.441,11.021-49.885,27.154-49.885,27.154
                        s-5.82,3.144-9.658,0.749c-19.396-12.1-47.656-33.594-84.912-45.562c-0.621-0.2-1.408-0.673-0.338-1.664l15.955-11.158
                        c0,0,1.25-1.08-0.355-1.602c-7.896-2.573-40.783-13.601-69.24-3.462c-5.797,2.065-10.555,3.761-14.467,5.155
                        c-1.682,0.6-3.391,1.305-6.799,1.726C52.482,117.237,0,174.203,0,196.737c0,15.123,8.154,25.271,37.947,39.378
                        c0.598-0.095,5.146,3.17,15.137,0.168c2.678-0.805,21.697-7.968,22.453-8.291c0.758-0.346,1.25-0.517,1.564-0.466
                        c0.404,0.064,0.701,0.962,0.699,1.144c-0.063,5.387-10.16,9.75-15.893,14.537c-0.984,0.459-1.248,2.744,0.475,3.484
                        c0.002,0,20.246,10.854,52.307,14.229c2.592,0.273,36.34,21.897,70.371,16.096c17.999-3.069,26.564-4.119,30.473-5.197
                        c3.412-0.94,1.783-2.022,1.783-2.022l-17.059-13.592c-1.155-1.281-0.221-2.265,0.746-2.539
                        c37.882-10.779,67.834-27.771,85.672-42.328c2.402-1.961,8.645,2.701,13.102,4.953c14.801,7.477,76.238,32.803,81.301,27.442
                        c0.436-0.452,0.467-1.125,0.023-2.05C372.456,223.524,341.21,208.035,353.165,182.381z M62.835,180.632
                        c-5.465,0-9.895-4.512-9.895-10.077s4.43-10.076,9.895-10.076s9.896,4.511,9.896,10.076S68.3,180.632,62.835,180.632z
                        M107.118,237.965c-0.609,0.547-1.164,1.373-0.842,0.185c0,0,15.426-23.21,17.426-53.211
                        c1.498-22.484-13.482-50.02-13.482-50.02s0.029-0.804,0.555-0.169C116.108,141.2,168.618,182.688,107.118,237.965z"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </span>
        </button>
      </div>

      <div class="system-log product-sync-log">
        <div
          v-for="entry in adminUserSyncLog"
          :key="entry.id"
          class="single-log-entry"
        >
          <div class="log-entry-top">
            <span class="completed">{{
              entry.completedAt.toDate() | moment("from", "now")
            }}</span>
            <span class="ran-by">{{ entry.ranBy }}</span>
          </div>

          <div class="log-entry-data">
            <h6 class="added">
              Added users: <span>{{ entry.added.length }}</span>
            </h6>
            <p
              v-for="(info, index) in entry.added"
              :key="entry.id + '-added-' + index"
              class="log-entry-line"
            >
              {{ info }}
            </p>

            <h6 class="updated">
              Updated users: <span>{{ entry.updated.length }}</span>
            </h6>
            <p
              v-for="(info, index) in entry.updated"
              :key="entry.id + '-updated-' + index"
              class="log-entry-line"
            >
              {{ info }}
            </p>
            <h6 class="removed">
              Removed users: <span>{{ entry.removed.length }}</span>
            </h6>
            <p
              v-for="(info, index) in entry.removed"
              :key="entry.id + '-deleted-' + index"
              class="log-entry-line"
            >
              {{ info }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="sync order-sync">
      <div class="sync-info">
        <h2>Order sync</h2>
        <p class="desc">Runs every 10 minutes</p>
        <button
          v-if="userRole.slug === 'superAdmin'"
          class="run-button sync-products"
          :class="{ loading: orderSyncLoader }"
          @click="syncOrders"
        >
          <span v-if="!orderSyncLoader">Run manually</span>
          <span v-else>
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="381.496px"
              height="381.496px"
              viewBox="0 0 381.496 381.496"
              style="enable-background: new 0 0 381.496 381.496"
              xml:space="preserve"
            >
              <g>
                <g>
                  <g id="Layer_5_13_">
                    <g>
                      <path
                        d="M353.165,182.381c1.217-2.613,9.533-13.636,17.062-25.062c0.007-0.008,0.013-0.016,0.017-0.023
                        c1.699-2.578,3.355-5.175,4.885-7.702c0.043-0.071,0.086-0.143,0.129-0.214c0.248-0.412,17.859-28.646-7.225-17.212
                        c0,0.002-0.002,0.005-0.004,0.007c-4.713,2.417-10.707,6.021-18.244,11.072c-16.441,11.021-49.885,27.154-49.885,27.154
                        s-5.82,3.144-9.658,0.749c-19.396-12.1-47.656-33.594-84.912-45.562c-0.621-0.2-1.408-0.673-0.338-1.664l15.955-11.158
                        c0,0,1.25-1.08-0.355-1.602c-7.896-2.573-40.783-13.601-69.24-3.462c-5.797,2.065-10.555,3.761-14.467,5.155
                        c-1.682,0.6-3.391,1.305-6.799,1.726C52.482,117.237,0,174.203,0,196.737c0,15.123,8.154,25.271,37.947,39.378
                        c0.598-0.095,5.146,3.17,15.137,0.168c2.678-0.805,21.697-7.968,22.453-8.291c0.758-0.346,1.25-0.517,1.564-0.466
                        c0.404,0.064,0.701,0.962,0.699,1.144c-0.063,5.387-10.16,9.75-15.893,14.537c-0.984,0.459-1.248,2.744,0.475,3.484
                        c0.002,0,20.246,10.854,52.307,14.229c2.592,0.273,36.34,21.897,70.371,16.096c17.999-3.069,26.564-4.119,30.473-5.197
                        c3.412-0.94,1.783-2.022,1.783-2.022l-17.059-13.592c-1.155-1.281-0.221-2.265,0.746-2.539
                        c37.882-10.779,67.834-27.771,85.672-42.328c2.402-1.961,8.645,2.701,13.102,4.953c14.801,7.477,76.238,32.803,81.301,27.442
                        c0.436-0.452,0.467-1.125,0.023-2.05C372.456,223.524,341.21,208.035,353.165,182.381z M62.835,180.632
                        c-5.465,0-9.895-4.512-9.895-10.077s4.43-10.076,9.895-10.076s9.896,4.511,9.896,10.076S68.3,180.632,62.835,180.632z
                        M107.118,237.965c-0.609,0.547-1.164,1.373-0.842,0.185c0,0,15.426-23.21,17.426-53.211
                        c1.498-22.484-13.482-50.02-13.482-50.02s0.029-0.804,0.555-0.169C116.108,141.2,168.618,182.688,107.118,237.965z"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </span>
        </button>
      </div>

      <div class="system-log product-sync-log">
        <div
          v-for="entry in orderSyncLog"
          :key="entry.id"
          class="single-log-entry"
        >
          <div class="log-entry-top">
            <span class="completed">{{
              entry.completedAt.toDate() | moment("from", "now")
            }}</span>
            <span class="ran-by">{{ entry.ranBy }}</span>
          </div>

          <div class="log-entry-data">
            <h6 v-if="entry.added !== undefined" class="added">
              Added orders: <span>{{ entry.added.length }}</span>
            </h6>
            <span v-if="entry.added !== undefined">
              <p
                v-for="(info, index) in entry.added"
                :key="entry.id + '-added-' + index"
                class="log-entry-line"
              >
                {{ info }}
              </p>
            </span>

            <h6 class="updated">
              Updated orders: <span>{{ entry.updated.length }}</span>
            </h6>
            <p
              v-for="(info, index) in entry.updated"
              :key="entry.id + '-updated-' + index"
              class="log-entry-line"
            >
              {{ info }}
            </p>
            <h6 class="removed">
              Removed orders: <span>{{ entry.removed.length }}</span>
            </h6>
            <p
              v-for="(info, index) in entry.removed"
              :key="entry.id + '-deleted-' + index"
              class="log-entry-line"
            >
              {{ info }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "System",
  data() {
    return {
      productSyncLoader: false,
      adminUserSyncLoader: false,
      userSyncLoader: false,
      companySyncLoader: false,
      orderSyncLoader: false,
    };
  },
  components: {},
  mounted() {},
  async created() {
    await this.$store.dispatch("logs/bindProductSyncLog");
    await this.$store.dispatch("logs/bindAdminUserSyncLog");
    await this.$store.dispatch("logs/bindUserSyncLog");
    await this.$store.dispatch("logs/bindCompanySyncLog");
    await this.$store.dispatch("logs/bindOrderSyncLog");
    this.$store.commit("logs/setInit", true);
  },
  beforeDestroy() {
    this.$store.dispatch("logs/unbindSystemLogs");
    this.$store.commit("logs/setInit", false);
  },
  computed: {
    ...mapState({
      productSyncLog: (state) => state.logs.productSyncLog,
      adminUserSyncLog: (state) => state.logs.adminUserSyncLog,
      userSyncLog: (state) => state.logs.userSyncLog,
      companySyncLog: (state) => state.logs.companySyncLog,
      orderSyncLog: (state) => state.logs.orderSyncLog,
      appSettings: (state) => state.settings.appSettings,
      userName: (state) => state.settings.user.name,
      appBrand: (state) => state.settings.appSettings.app,
      appEnv: (state) => state.settings.appSettings.env,
      init: (state) => state.logs.init,
    }),
    ...mapGetters("settings", ["userRole"]),
  },
  methods: {
    syncProducts: function () {
      if (this.productSyncLoader) {
        return false;
      }

      this.productSyncLoader = true;

      this.axios
        .post(this.appSettings.cloudFunctions + "adminSyncProductsManually", {
          env: this.appEnv,
          brand: this.appBrand,
          user: this.userName,
        })
        .then((res) => {
          console.log(res);
          console.log(res.data.message);
          this.productSyncLoader = false;
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
          }
          this.productSyncLoader = false;
        });
    },
    syncAdminUsers: function () {
      if (this.adminUserSyncLoader) {
        return false;
      }

      this.adminUserSyncLoader = true;

      this.axios
        .post(this.appSettings.cloudFunctions + "adminSyncAdminUsersManually", {
          env: this.appEnv,
          brand: this.appBrand,
          user: this.userName,
        })
        .then((res) => {
          console.log(res);
          console.log(res.data.message);
          this.adminUserSyncLoader = false;
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
          }
          this.adminUserSyncLoader = false;
        });
    },
    syncUsers: function () {
      if (this.userSyncLoader) {
        return false;
      }

      this.userSyncLoader = true;

      this.axios
        .post(this.appSettings.cloudFunctions + "adminSyncUsersManually", {
          env: this.appEnv,
          brand: this.appBrand,
          user: this.userName,
        })
        .then((res) => {
          console.log(res);
          console.log(res.data.message);
          this.userSyncLoader = false;
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
          }
          this.userSyncLoader = false;
        });
    },
    syncCompanies: function () {
      if (this.companySyncLoader) {
        return false;
      }

      this.companySyncLoader = true;

      this.axios
        .post(this.appSettings.cloudFunctions + "adminSyncCompaniesManually", {
          env: this.appEnv,
          brand: this.appBrand,
          user: this.userName,
        })
        .then((res) => {
          console.log(res);
          console.log(res.data.message);
          this.companySyncLoader = false;
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
          }
          this.companySyncLoader = false;
        });
    },
    syncOrders: function () {
      if (this.orderSyncLoader) {
        return false;
      }

      this.orderSyncLoader = true;

      this.axios
        .post(this.appSettings.cloudFunctions + "adminSyncOrdersManually", {
          env: this.appEnv,
          brand: this.appBrand,
          user: this.userName,
        })
        .then((res) => {
          console.log(res);
          console.log(res.data.message);
          this.orderSyncLoader = false;
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
          }
          this.orderSyncLoader = false;
        });
    },
  },
};
</script>
